<template>
  <b-form-select :id="id" :value="content" @input="setLang" :state="langState">
    <b-form-select-option :value="null" disabled>
      - {{ $t('dial.selopt') }} -
    </b-form-select-option>
    <b-form-select-option v-for="(lang, idx) in lList"
        :key="idx" :value="idx">
      {{ $t('lng.' + lList[idx]) }}
    </b-form-select-option>
  </b-form-select>
</template>

<script>
import { switchLangAsync } from '@/plugins/i18n';
import langList from '@/components/data/Languages';

export default {
  data() {
    return {
      content: this.value,
    };
  },
  watch: {
    value: function(n) {
      this.content = n;
    },
    autoload: function(n, o) {
      if (n === true && o === false) {
        this.execute(this.content);
      }
    },
  },
  computed: {
    lList() {
      return langList;
    },
    settLangId() {
      return this.$store.state.settings.languageId;
    },
    langState() {
      if (this.content === this.settLangId) {
        return (this.content !== this.$store.state.oUser.languageId) ? true : null;
      }
      return false;
    },
  },
  methods: {
    async setLang(id) {
      let p = Promise.resolve();
      if (this.autoload) {
        p = this.execute(id);
      }
      await p.finally(() => {
        this.content = id;
        this.$emit('input', this.content);
      });
    },
    async execute(id) {
      return await switchLangAsync(this.settLangId, langList[id]).then(() => {
        if (this.settLangId !== id) {
          this.$store.dispatch('setLanguage', id);
        }
      });
    }
  },
  props: {
    value: {
      type: Number,
      default: 1,
    },
    id: {
      type: String,
      required: true,
    },
    autoload: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
