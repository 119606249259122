<template>
  <b-modal :id="id" ref="modal" :title="title" :size="size" lazy
      @show="$emit('show', $event)" @shown="$emit('shown', $event)" @hide="onHide">
    <b-form class="text-center mt-3" :id="id + 'Form'" @submit.stop.prevent>
      <b-img v-if="!hidePic" class="mb-4 rounded" src="@/assets/logo.png" :alt="$t('nav.brand')" width="150" />
      <slot />
    </b-form>

    <template #modal-footer>
      <b-button type="submit" variant="primary" block
          :size="btnSize || `lg`" :id="[id + 'Btn']" :disabled="btnDisabled"
          @click="$emit('submit')">
        <span class="text-nowrap"><Icon v-if="btnIcon!==null" :type="btnIcon" /> {{ btnText }}</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Icon from '@/components/Icon';

// !!! careful !!! modal is not destroyed on hide! created/mounted are also only executed once. show/hide is always executed
export default {
  data() {
    return {
    }
  },
  methods: {
    hide() {
      this.$refs.modal.hide(); // no argument, so we can safely close the window with if(null) below
    },
    onHide(evt) {
      // take all hide events, but the correct "ok/accept"
      if (evt.trigger !== null) {
        this.$emit('reset');
      }
    },
  },
  props: {//['id', 'title', 'btnText', 'hidePic', 'size', 'btnSize', 'btnDisabled']
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    btnText: {
      type: String,
      default: null,
    },
    btnSize: {
      type: String,
      default: 'md',
    },
    btnIcon: {
      type: String,
      default: null,
    },
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    hidePic: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    }
  },
  components: {
    Icon,
  },
}
</script>
